/* eslint no-negated-condition: "off"*/

import React from "react";

import { toUrl } from "gatsby-source-gravatar";

import { Comment } from "../../../types/comment";

interface Props {
  title: string;
  comments: Array<Comment> | undefined;
}

const Comments: React.FC<Props> = ({ comments, title }: Props) => {
  if (!comments) {
    return null;
  }

  return (
    <div className="flow-root">
      <h3>
        {comments.length} thoughts on "{title}"
      </h3>

      {comments.filter((item) => item.approved === '1').map((activityItem, activityItemIdx) => (
        <div className="relative pb-8">
          {activityItemIdx !== comments.length - 1 ? (
            <span
              className="absolute left-5 top-5 -ml-px h-full w-0.5"
              aria-hidden="true"
            />
          ) : null}
          <div className="relative flex items-start space-x-3">
            <div className="relative">
              <img
                className="flex items-center justify-center w-10 h-10 bg-gray-400 rounded-full ring-1 ring-white"
                src={toUrl(activityItem.author_email || "none")}
                alt=""
              />
            </div>
            <div className="flex-1 min-w-0">
              <div>
                <div className="text-sm font-bold text-white">
                  {activityItem.author}
                </div>
                <p className="mb-0 mt-0.5 text-sm text-gray-500">
                  Commented {activityItem.date}
                </p>
              </div>
              <div className="text-sm text-white">
                {activityItem.content.split("\n").map((line) => (
                  <p>{line}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Comments;
