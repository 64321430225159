import React from "react";

import { Image } from "@/components/Image";

import * as styles from "./Content.module.scss";

interface Props {
  title: string;
  body: string;
  coverImage: string | undefined;
}
// const image = socialImage?.publicURL && url.concat(socialImage?.publicURL);
const Content: React.FC<Props> = ({ body, title, coverImage }: Props) => (
  <div className={styles.content}>
    <h1 className={styles.title}>{title}</h1>
    <div className="object-center w-full">
      {coverImage && (
        <center>
          <div className="max-w-sm">
            <Image alt="cover iamge" path={coverImage} />
          </div>
        </center>
      )}
    </div>
    <div className={styles.body} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
